import LanguageDetector, { CustomDetector, DetectorOptions } from 'i18next-browser-languagedetector';

class UrlSearchParamsDetector implements CustomDetector {
  readonly name = 'UrlSearchParamsDetector';
  readonly keys = ['culture', 'locale', 'language'];
  readonly localeRegularExpression = /^[a-z]{2}-[A-Z]{2}$/;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  lookup(options: DetectorOptions): string | string[] | undefined {
    const params = new URLSearchParams(window.location.search);
    return Array.from(params.entries())
      .filter(([key, value]) => this.keys.includes(key) && value && this.localeRegularExpression.test(value))
      .map(([, value]) => value) as string[];
  }
}

const urlSearchParamsDetector = new UrlSearchParamsDetector();

export const languageDetector = new LanguageDetector(
  { languageUtils: {} },
  {
    order: [urlSearchParamsDetector.name, 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag'],
    lookupCookie: 'culture',
    caches: ['cookie']
  }
);

languageDetector.addDetector(new UrlSearchParamsDetector());

export function detectLocale() {
  const detected = languageDetector.detect();
  return Array.isArray(detected) ? detected[0] : detected;
}
