import { AxiosRequestConfig } from 'axios';

export const ylRequestIdHeaderKey = 'YL-Request-Id';

export class ApiClientBase {
  protected transformOptions(options: AxiosRequestConfig) {
    options.transformResponse = (data: any) => data;
    return Promise.resolve(options);
  }
}
