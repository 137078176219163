import { Instance, types } from 'mobx-state-tree';

export function removeCurrentCookieConsent() {
  sessionStorage.removeItem('cookie-consent-accepted');
}

export const CookieWarningModalStore = types
  .model('CookieWarningModalStore', {
    showModal: types.boolean
  })
  .views(self => ({
    get show(): boolean {
      return self.showModal && sessionStorage.getItem('cookie-consent-accepted') === null;
    }
  }))
  .actions(self => ({
    close(): void {
      sessionStorage.setItem('cookie-consent-accepted', 'true');
      self.showModal = false;
    }
  }));

export type CookieWarningModalStore = Instance<typeof CookieWarningModalStore>;
