import axios, { AxiosInstance } from 'axios';
import {
  addYlRequestIdInterceptor,
  addEnrollmentIdInterceptor,
  addMarketShieldInterceptor,
  addRetryInterceptor,
  addRedirectInterceptor
} from './interceptors';

export const baseURL = '/apps/enrollment';

export const enrollmentResource = axios.create({
  baseURL: `${baseURL}/api`
});

export const enrollmentClient = axios.create({ baseURL: YL_BASE_URL });

function setupInterceptors(instance: AxiosInstance) {
  addEnrollmentIdInterceptor(instance);
  addYlRequestIdInterceptor(instance);
  addMarketShieldInterceptor(instance);
  addRetryInterceptor(instance);
  addRedirectInterceptor(instance);
}

setupInterceptors(enrollmentClient);
setupInterceptors(enrollmentResource);
