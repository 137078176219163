import { SnapshotIn } from 'mobx-state-tree';
import { enrollmentResource } from '../../infrastructure/http/EnrollmentResource';
import { Currency } from '../../reference/Currency';
import { EnrollmentStep } from '../enrollment-process/steps-management/model/EnrollmentStep';
import { StoreLanguage } from '../locale-management/locale-manager/StoreLanguage';
import { EnrollmentConfigTag } from './EnrollmentStatus';

export interface StoreSpecificConfig {
  currency: Currency;
  steps: SnapshotIn<EnrollmentStep>[];
  languages: SnapshotIn<StoreLanguage>[];
}

export async function getStoreSpecificConfiguration(storeId: number, tag: EnrollmentConfigTag | undefined) {
  const response = await enrollmentResource.get<StoreSpecificConfig>(`/enrollment-process/store/${storeId}`, {
    params: { tag }
  });
  return response.data;
}
