import { establishUniversalDomainSession, revokeUniversalDomainSession } from '@yl/sso-single-logout';

import { AuthenticationToken } from '../../external/shared/api/EnrollmentClient.generated';

const authTokenKey = 'authToken';

export function getVoAuthToken(): AuthenticationToken | undefined {
  const tokenString = localStorage.getItem(authTokenKey);
  return tokenString ? JSON.parse(tokenString) : undefined;
}

export function isExpired(authenticationToken: AuthenticationToken) {
  const expires = new Date(authenticationToken.expireDate);
  const now = new Date();
  return expires < now;
}

export function setupVoAuthentication(authenticationToken: AuthenticationToken) {
  localStorage.setItem(authTokenKey, JSON.stringify(authenticationToken));
  establishUniversalDomainSession(authenticationToken.memberId.toString());
}

export function removeVoAuthentication(): AuthenticationToken | undefined {
  const authToken = getVoAuthToken();
  localStorage.removeItem(authTokenKey);
  revokeUniversalDomainSession();
  return authToken;
}
