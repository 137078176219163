import { configure } from 'mobx';

let configured = false;

export const configureMobx = () => {
  // prettier-ignore
  // eslint-disable-next-line no-prototype-builtins
  if (!new class { x: any }().hasOwnProperty('x')) throw new Error('Transpiler is not configured correctly');

  // Important if multiple versions of mobx are ever loaded.
  if (!configured) {
    configure({ isolateGlobalState: true });
    configured = true;
  }
};
