import { Redirector } from '../../infrastructure/redirecting/Redirector';
import { baseURL } from '../../infrastructure/http/EnrollmentResource';
import { getCurrentEnrollmentId } from '../customer-enrollment-status/EnrollmentStatusManager';
import { EnrollmentConfigTag } from '../customer-enrollment-status/EnrollmentStatus';

export const EnrollmentRedirect = {
  getRedirectData(): RedirectViewModel | undefined {
    const redirectData = localStorage.getItem('redirectData');
    return redirectData ? (JSON.parse(redirectData) as RedirectViewModel) : undefined;
  },

  setRedirectData(redirectData: RedirectViewModel) {
    localStorage.setItem('redirectData', JSON.stringify(redirectData));
  },

  removeRedirectData(): void {
    localStorage.removeItem('redirectData');
  },

  validatePathAndRedirectIfNecessary(): void {
    const redirectData = this.getRedirectData();

    if (redirectData?.enabled) {
      const currentPath = window.location.pathname;
      if (!currentPath.includes(redirectData.url)) {
        Redirector.redirect(redirectData.url);
      }
    } else if (!getCurrentEnrollmentId()) {
      if (process.env.NODE_ENV === 'development') {
        this.setRedirectData({
          enabled: true,
          url: baseURL,
          countryIsoCode: 'GB',
          phase: EnrollmentConfigTag.Phase2
        });
      }
      Redirector.redirect('/vo/#/enroll/start');
    }
  },

  applyUrlSearchParams() {
    const params = new URLSearchParams(window.location.search);
    const redirectData = this.getRedirectData();
    if (redirectData) {
      const tag = params.get('tag');
      const country = params.get('country') || params.get('countryIsoCode');
      if (tag || country) {
        this.setRedirectData({
          ...redirectData,
          phase: (tag ?? redirectData.phase) as EnrollmentConfigTag,
          countryIsoCode: country ?? redirectData.countryIsoCode
        });
      }
    }
  }
};

export interface RedirectViewModel {
  enabled: boolean;
  url: string;
  phase?: EnrollmentConfigTag;
  socialLinkId?: string;
  countryIsoCode?: string;
  countryIsoCode3?:string;
}
