import { SnapshotIn, types, Instance } from 'mobx-state-tree';

export enum EnrollmentConfigTag {
  Phase1 = 'Phase1',
  Phase2 = 'Phase2',
  Phase2PC = 'Phase2PC',
  SocialLink = 'SocialLink',
  Phase2CreatorMode = 'Phase2CreatorMode',
  Simplified = 'Simplified',
  SimplifiedCheckout = 'SimplifiedCheckout',
  Phase2EarlyBilling = 'Phase2EarlyBilling'
}

export const EnrollmentStatus = types.model('EnrollmentStatus', {
  enrollmentId: types.string,
  storeId: types.number,
  tag: types.maybe(types.enumeration<EnrollmentConfigTag>(Object.values(EnrollmentConfigTag))),
  /** IsoCode2 */
  countryIso: types.string,
  socialLinkHasExpired: types.boolean,
  countryIso3:types.optional(types.string,'')
});

export interface EnrollmentStatusFromServer extends SnapshotIn<typeof EnrollmentStatus> {
  locale: string;
  currentStep: number;
}

export type EnrollmentStatus = Instance<typeof EnrollmentStatus>;
