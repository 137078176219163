import axiosStatic, { AxiosInstance, AxiosRequestConfig } from 'axios';
import cookies from 'browser-cookies';
import md5 from 'md5';
import { v4 as uuidv4 } from 'uuid';
import utf8 from 'utf8';

import { errorModalStore } from '../../external/shared/error-handling/ErrorModalStore';
import { Redirector } from '../redirecting/Redirector';
import { baseURL } from './EnrollmentResource';
import { getCurrentEnrollmentId } from '../../app/customer-enrollment-status/EnrollmentStatusManager';
import { getVoAuthToken, setupVoAuthentication } from '../../app/authentication/VoAuthentication';
import { TokenClient } from '../../external/shared/api/EnrollmentClient.generated';

export function addEnrollmentIdInterceptor(axios: AxiosInstance) {
  axios.interceptors.request.use(config => {
    const currentEnrollmentId = getCurrentEnrollmentId();
    if (currentEnrollmentId) {
      config.headers ||= {};
      config.headers['YL-EnrollmentId'] = currentEnrollmentId;
    }
    return config;
  });
}

export function addYlRequestIdInterceptor(axios: AxiosInstance) {
  axios.interceptors.request.use(config => {
    config.headers ||= {};
    config.headers['YL-Request-Id'] = uuidv4();
    return config;
  });
  axios.interceptors.response.use(undefined, error => {
    if (!axiosStatic.isCancel(error)) {
      const requestId = error.response?.headers['yl-request-id'] || error.config?.headers['YL-Request-Id'];
      errorModalStore.showError(requestId);
    }
    return Promise.reject(error);
  });
}

export function addMarketShieldInterceptor(axios: AxiosInstance) {
  axios.interceptors.response.use(response => {
    if (response?.data?.isShielded) {
      const storedAccess = cookies.get('ms-axs');

      let verified = false;
      if (storedAccess) {
        const encryptedHash = md5(response.data.shieldAccessCode);

        if (storedAccess === encryptedHash) {
          verified = true;
        }
      }

      if (!verified) {
        Redirector.redirect('/vo/#/market-shield');
      }
    }

    return response;
  });
}

export function addRetryInterceptor(axios: AxiosInstance) {
  axios.interceptors.response.use(undefined, error => {
    if (error.config && error.response?.status === 502 && (error.config.num502Retries || 0) < 10) {
      const config = error.config;
      config.num502Retries = config.num502Retries || 0;
      config.num502Retries++;
      config.url = config.url.replace(config.baseURL, '');
      return axios.request(error.config);
    }
    return Promise.reject(error);
  });
}

export function addRedirectInterceptor(axios: AxiosInstance) {
  axios.interceptors.response.use(
    response => {
      if (response?.data?.redirectToStepOne) {
        Redirector.redirect(baseURL);
      }
      return response;
    },
    error => {
      if (error?.response?.status === 303) {
        return Redirector.redirect(baseURL);
      }
      return Promise.reject(error);
    }
  );
}

export function addVoAuthTokenInterceptor(axios: AxiosInstance) {
  axios.interceptors.request.use(config => {
    const token = getVoAuthToken();
    config.headers ||= {};
    config.headers.AuthToken = btoa(utf8.encode(JSON.stringify(token)));
    return config;
  });
  const tokenClient = new TokenClient('', axios);
  axios.interceptors.response.use(undefined, async error => {
    if (error?.response?.status === 401 && error?.response?.data?.reason === 'Reissue') {
      const token = await tokenClient.reissue((error.config as AxiosRequestConfig).cancelToken);
      setupVoAuthentication(token);
      return axios.request(error.config);
    }
    throw error;
  });
}
