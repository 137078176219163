import { history } from '@yl/react-router';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

export const dataLayer = window.dataLayer;

export function sendHistoryEventsToAnalytics() {
  history.listen(() => {
    dataLayer.push({
      event: 'content-view',
      'content-name': history.location.pathname
    });
  });
}
export type ECommerceStatus =
  | 'successful-payment'
  | 'pending-payment'
  | 'failed-payment'
  | 'failed-request'
  | 'failed-unknown'
  | 'early-enrollment-completed';

export function publishECommerceCompletionEvent(status: ECommerceStatus, data?: CompletionAnalyticsEventData) {
  let event;
  if (data) {
    event = data.hasEr ? 'New Member Enrollment With ER' : 'New Member Enrollment Without ER';
  }
  dataLayer.push({
    event,
    status,
    sponsorId: data ? data.sponsorId : undefined,
    enrollerId: data ? data.enrollerId : undefined,
    memberType: data ? data.memberType : undefined,
    paymentType: data ? data.paymentType : undefined,
    ecommerce: {
      currencyCode: data ? data.currencyCode : undefined,
      purchase: {
        actionField: {
          coupon: '',
          affiliation: event,
          id: data ? data.orderId : undefined,
          revenue: data ? data.revenue : undefined,
          tax: data ? data.tax : undefined,
          shipping: data ? data.shipping : undefined
        },
        products: data ? data.products : undefined
      }
    }
  });
}

export interface CompletionAnalyticsEventData {
  hasEr: boolean;
  enrollerId: string;
  sponsorId: string;
  revenue: string;
  shipping: string;
  orderId: string;
  tax: string;
  currencyCode: string;
  memberType: string;
  paymentType: string;
  products: ECommerceProduct[];
}

export type AnalyticEventTypes = 'content-view';

export type EnrollmentCompletionEventType = 'New Member Enrollment With ER' | 'New Member Enrollment Without ER';

export type AnalyticsPaymentType = 'CreditCard';

export type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? RecursivePartial<U>[]
    : T[P] extends object
    ? RecursivePartial<T[P]>
    : T[P];
};

export type ECommerceAnalyticsEventPartial = RecursivePartial<ECommerceAnalyticsEvent>;

export interface ECommerceActionField {
  coupon: string;
  affiliation: EnrollmentCompletionEventType;
  id: string;
  revenue: string;
  tax: string;
  shipping: string;
}

export interface ECommerceProduct {
  name: string;
  id: string;
  price: string;
  quantity: string;
}

export interface ECommercePurchase {
  actionField: ECommerceActionField;
}

export interface ECommerceAnalyticsEvent {
  currencyCode: string;
  purchase: ECommercePurchase;
}

export interface EnrollmentAnalyticsEvent {
  event: EnrollmentCompletionEventType;
  sponsorId: string;
  enrollerId: string;
  memberType: string;
  paymentType: AnalyticsPaymentType;
  ecommerce: ECommerceAnalyticsEvent;
}
