import { history } from '@yl/react-router/dist';
import { differenceInDays } from 'date-fns';

interface LinkParams {
  sponsorid?: string;
  enrollerid?: string;
  dateCaptures?: number;
}

export function getLinkParams(): LinkParams | undefined {
  if (!localStorage.link_params) {
    return undefined;
  }
  const linkParams = JSON.parse(localStorage.link_params);
  const dateCaptured = new Date(linkParams.dateCaptured);
  const today = new Date();
  if (differenceInDays(today, dateCaptured) > 7) {
    return undefined;
  }
  return linkParams;
}

export function collectLinkParams() {
  const params = new URLSearchParams(history.location.search);

  // noinspection SpellCheckingInspection
  const linkParams: LinkParams = {
    sponsorid: params.get('sponsorid') ?? undefined,
    enrollerid: params.get('enrollerid') ?? undefined,
    dateCaptures: new Date().getTime()
  };

  if (linkParams.sponsorid || linkParams.enrollerid) {
    localStorage.link_params = JSON.stringify(linkParams);
  }
}
