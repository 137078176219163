import {
  createInitialEnrollmentStatusOrRedirect,
  persistEnrollmentStatus
} from './app/customer-enrollment-status/EnrollmentStatusManager';
import { getStoreSpecificConfiguration } from './app/customer-enrollment-status/StoreSpecificConfig';
import { collectLinkParams } from './app/customer-enrollment-status/LinkParams';
import { errorModalStore } from './external/shared/error-handling/ErrorModalStore';
import { EnrollmentRedirect } from './app/root/EnrollmentRedirect';
import { disableBackForwardCache } from './app/routing/disableBackForwardCache';
import { sendHistoryEventsToAnalytics } from './infrastructure/analytics/gtm-support';
import { EnrollmentStatusFromServer } from './app/customer-enrollment-status/EnrollmentStatus';

try {
  EnrollmentRedirect.validatePathAndRedirectIfNecessary();
  EnrollmentRedirect.applyUrlSearchParams();
  collectLinkParams();
  disableBackForwardCache();
  sendHistoryEventsToAnalytics();
} catch (error) {
  errorModalStore.showError();
}

export async function bootstrapData(enrollmentStatus?: EnrollmentStatusFromServer) {
  enrollmentStatus ??= await createInitialEnrollmentStatusOrRedirect();
  if (!enrollmentStatus) return undefined;
  const storeSpecificConfig = await getStoreSpecificConfiguration(enrollmentStatus.storeId, enrollmentStatus.tag);
  persistEnrollmentStatus(enrollmentStatus);
  return { enrollmentStatus, storeSpecificConfig };
}

export const dataPromise = bootstrapData();

import('./bootstrapReact' /* webpackChunkName: 'bootstrapReact' */);
