import axios from 'axios';

import { enrollmentResource } from '../../infrastructure/http/EnrollmentResource';
import { Redirector } from '../../infrastructure/redirecting/Redirector';
import { EnrollmentRedirect } from '../root/EnrollmentRedirect';
import { getLinkParams } from './LinkParams';
import { removeCurrentCookieConsent } from '../cookie-warning/CookieWarningModalStore';
import { EnrollmentStatusFromServer } from './EnrollmentStatus';
import { removeVoAuthentication } from '../authentication/VoAuthentication';
import { detectLocale, languageDetector } from '../../external/shared/localization/languageDetector';
import { errorModalStore } from '../../external/shared/error-handling/ErrorModalStore';

function getCartIdToUse(enrollmentId: string | null | undefined) {
  const cartQuantity = localStorage.getItem('shopping-cart-quantity');
  const cart = localStorage.getItem('shoppingCartId');
  if (cartQuantity == null || cart == null || (enrollmentId != null && cart === enrollmentId)) {
    return null;
  }

  return parseInt(cartQuantity, 10) <= 0 ? null : JSON.parse(cart);
}

export function getCurrentEnrollmentId() {
  return sessionStorage.getItem('enrollment-id');
}

export function removeCurrentEnrollmentId() {
  sessionStorage.removeItem('enrollment-id');
}

export async function createInitialEnrollmentStatus(): Promise<EnrollmentStatusFromServer> {
  const redirectData = EnrollmentRedirect.getRedirectData();

  if (redirectData?.socialLinkId) {
    const socialLinkResponse = await enrollmentResource.post<EnrollmentStatusFromServer>(
      `social-links/${redirectData.socialLinkId}/enrollment-status`
    );
    return socialLinkResponse.data;
  }

  const enrollmentId = getCurrentEnrollmentId();
  const linkParams = getLinkParams();
  const locale = detectLocale();
  const cartId = getCartIdToUse(enrollmentId);
  const response = await enrollmentResource.post<EnrollmentStatusFromServer>('enrollment-status', {
    enrollmentId,
    locale,
    cartId,
    tag: redirectData?.phase,
    countryIso: redirectData?.countryIsoCode,
    enrollerId: linkParams?.enrollerid,
    sponsorId: linkParams?.sponsorid
  });

  return response.data;
}

export async function getNextEnrollmentStatus(enrollmentId: string) {
  const response = await enrollmentResource.post<EnrollmentStatusFromServer>('enrollment-status', { enrollmentId });
  return response.data;
}

function cleanupClientStorages() {
  localStorage.removeItem('shopping-cart-quantity');
  localStorage.removeItem('shoppingCartId');
  localStorage.removeItem('i18nextLng');
  sessionStorage.removeItem('country-iso-code');
  EnrollmentRedirect.removeRedirectData();
  removeVoAuthentication();
}

export function persistEnrollmentStatus(enrollmentStatus: EnrollmentStatusFromServer) {
  cleanupClientStorages();
  sessionStorage.setItem('enrollment-id', enrollmentStatus.enrollmentId);
  languageDetector.cacheUserLanguage(enrollmentStatus.locale);
}

export function cancelEnrollment(
  enrollmentId?: string | null,
  toRoot?: boolean,
  country?: string,
  language?: string,
  tag?: string
) {
  removeCurrentEnrollmentId();
  removeCurrentCookieConsent();
  cleanupClientStorages();

  const entries = Object.entries({ toRoot, country, language, tag })
    .filter(([_, value]) => !!value)
    .map(([key, value]) => [key, value!.toString()]);

  const searchParams = new URLSearchParams(entries).toString();

  Redirector.redirect(`/apps/enrollment/cancel/${enrollmentId || ''}${searchParams ? '?' + searchParams : ''}`);
}

export async function createInitialEnrollmentStatusOrRedirect(): Promise<EnrollmentStatusFromServer | undefined> {
  try {
    return await createInitialEnrollmentStatus();
  } catch (error) {
    console.error(error);
    if (axios.isAxiosError(error) && error.response?.status === 400) {
      errorModalStore.close();
      const { countryIsoCode, phase } = EnrollmentRedirect.getRedirectData() ?? {};
      cancelEnrollment(getCurrentEnrollmentId(), false, countryIsoCode, detectLocale(), phase);
      return undefined;
    }
    throw error;
  }
}
