import { makeAutoObservable, when } from 'mobx';

import { configureMobx } from '../utils/configureMobx';

export class ErrorModalStore {
  constructor() {
    configureMobx();
    makeAutoObservable(this);
  }

  errorId: string | boolean = false;

  get shortErrorId(): string | undefined {
    if (typeof this.errorId === 'string') {
      return /((\d|\w)+)-/.exec(this.errorId)?.[1];
    }
    return undefined;
  }

  async showError(requestId?: string) {
    this.errorId ||= requestId || true;
    await when(() => !this.errorId);
  }

  close() {
    this.errorId = false;
  }
}

export const errorModalStore = new ErrorModalStore();
